import { ref, watch } from 'vue';

export function useLicensePlate() {
  const licensePlate = ref('');
  const licensePlateError = ref('');

  watch(licensePlate, (newValue, oldValue) => {
    licensePlateError.value = null;

    // Formater la plaque d'immatriculation avec des tirets après les 2 premiers et les 5 premiers caractères
    if (oldValue.length < newValue.length && newValue.length === 3 && newValue[2] !== '-') {
      licensePlate.value = `${oldValue}-${newValue[2]}`;
    }

    if (oldValue.length < newValue.length && newValue.length === 7 && newValue[6] !== '-') {
      licensePlate.value = `${oldValue}-${newValue[6]}`;
    }

    // Convertir la plaque d'immatriculation en majuscules
    licensePlate.value = licensePlate.value.toUpperCase();

    // Vérifier le format de la plaque d'immatriculation
    const regexWithDash = /^[A-Z]{2}-[0-9]{3}-[A-Z]{2}$/;
    const regexWithoutDash = /^[A-Z]{2}[0-9]{3}[A-Z]{2}$/;

    if (!regexWithDash.test(licensePlate.value) && !regexWithoutDash.test(licensePlate.value)) {
      if (licensePlate.value.length > 0) {
        licensePlateError.value = "Plaque d'immatriculation invalide. Veuillez utiliser le format AA-123-AA.";
      }
      return;
    }
  });

  return {
    licensePlate,
    licensePlateError
  };
}
