import {defineStore} from "pinia";

export const useEstimateStore = defineStore("estimate", {
	state: () => ({
		currentStep: null,
		steps: [
			{
				name: "type-vehicle",
				description: "Saisir votre véhicule",
				step: 1
			},
			{
				name: "confirm-typed-vehicle",
				description: "Informations du véhicule",
				step: 2
			},
			{
				name: "verify",
				description: "Informations du véhicule",
				step: 1
			},
			{
				name: "version",
				description: "Finition et version",
				step: 2
			},
			{
				name: "information",
				description: "Votre véhicule - Options",
				step: 3
			},
			{
				name: "interior",
				description: "Intérieur",
				step: 4
			},
			{
				name: "pneumatic",
				description: "Pneumatiques",
				step: 5
			},
			{
				name: "car-body",
				description: "Carrosserie",
				step: 6
			},
			{
				name: "optic",
				description: "Optiques",
				step: 7
			},
			{
				name: "comment",
				description: "Commentaires",
				step: 8
			},
			{
				name: "pictures",
				description: "Photos",
				step: 9
			},
			{
				name: "send",
				description: "Envoi de votre demande",
				step: 9
			},
			{
				name: "success",
				description: "Félicitations !",
				step: 9
			},
		],
		vehicleInfosFromRegister: null,
		// Referential
		brands: [],
		models: [],
		gears: [],
		bodies: [],
		energies: [],
		// Vehicle
		onlineQuoteId: null,
		register: null,
		vehicle: {},
		state: {},
		versions: [],
		pictures: []
	}),
	actions: {
		getStepDatas(step) {
			return this.steps.find(s => s.name === step)?.data
		},
		initiate(data) {
			this.onlineQuoteId = data.id
			localStorage.setItem("onlineQuoteId", data.id);
			this.register = data.register
			this.vehicle = data.vehicle
			this.state = data.state
			this.energies = data.energies
			this.gears = data.gears
			this.bodies = data.bodies
			if(data.brands) this.brands = data.brands
			if(data.models) this.models = data.models
			if(data.versions) this.versions = data.versions

			if(data.status === 0){
				if(data.vehicle.mode === 'automatic') this.currentStep = 'verify'
				else this.currentStep = 'type-vehicle'
			}
		},
		addPicture (picture) {
			this.pictures.push(picture)
		},
		removePicture (url) {
			const keyToDelete = this.pictures.findIndex(element => element.url === url)
			this.pictures.splice(keyToDelete, 1)
		},
	}
});
